<template>
    <div>
        <AdminTop
            heading="Lunchtider"
            add-new-url="/admin/lunch/events/new"
        />

        <BaseTable :show-delete="true">
            <TableHeader>
                <TableHeaderCell>
                    Datum
                </TableHeaderCell>

                <TableHeaderCell>
                    Tid
                </TableHeaderCell>

                <TableHeaderCell>
                    Repeterbar
                </TableHeaderCell>
            </TableHeader>

            <TableBody>
                <TableRow
                    v-for="(lunchEvent, index) in lunchEvents"
                    :key="lunchEvent.id"
                    :to="`/admin/lunch/events/${lunchEvent.id}`"
                    @delete="removeLunchEvent(lunchEvent.id, index)"
                >
                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ parseISO(lunchEvent.start).toLocaleDateString('sv-SE') }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ parseISO(lunchEvent.start).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) }} - {{ parseISO(lunchEvent.end).toLocaleTimeString('sv-SE', { hour: '2-digit', minute: '2-digit' }) }}
                        </p>
                    </TableCell>

                    <TableCell>
                        <p class="text-gray-900 whitespace-no-wrap">
                            {{ lunchEvent.repeat_until ? parseISO(lunchEvent.repeat_until).toLocaleDateString('sv-SE') : 'Nej' }}
                        </p>
                    </TableCell>
                </TableRow>
            </TableBody>
        </BaseTable>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseTable from '@/components/table/BaseTable';
import TableHeader from '@/components/table/TableHeader';
import TableHeaderCell from '@/components/table/TableHeaderCell';
import TableBody from '@/components/table/TableBody';
import TableRow from '@/components/table/TableRow';
import TableCell from '@/components/table/TableCell';
import LunchEventService from '@/services/admin/LunchEventService';
import parseISO from 'date-fns/parseISO';
import { confirmRemove } from '@/utils/utils.js';

export default {
    components: {
        AdminTop,
        BaseTable,
        TableHeader,
        TableHeaderCell,
        TableBody,
        TableRow,
        TableCell
    },

    data: () => ({
        lunchEvents: []
    }),

    created () {
        this.getLunchEvents();
    },

    methods: {
        async getLunchEvents () {
            const res = await LunchEventService.all();

            this.lunchEvents = res.data;
        },

        async removeLunchEvent (id, index) {
            if (!confirmRemove()) {
                return;
            }

            const res = await LunchEventService.remove(id);

            if (res && res.status === 200) {
                const lunchEvents = [...this.lunchEvents];

                lunchEvents.splice(index, 1);

                this.lunchEvents = lunchEvents;
            }
        },

        parseISO
    }
};
</script>

<style lang="postcss" scoped>
</style>
